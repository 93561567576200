@import "src/assets/scss/foundations.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.desktopImage {
  display: none;
}

.mobileImage {
  display: block;
  max-width: 100%;
}

.action-buttons {
  display: flex;
  width: 100%;
  max-width: 805px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    flex-wrap: wrap; /* 카드들을 두 줄로 배치 가능하게 함 */
    gap: 0px;
    margin-top: 30px;
    width: 100%;
    margin-bottom: 80px;

    .br-tag {
      display: block;
    }
  }

  .br-tag {
    display: none;
  }
}

@media (min-width: 768px) {
  .desktopImage {
    display: block;
    max-width: 100%;
  }

  .mobileImage {
    display: none;
  }

  .title {
    font-size: 32px;
    text-align: center;
  }
}

.button-group {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 180px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .submit-button {
    background-color: $primary300;
    color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    width: 288px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
