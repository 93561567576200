.toast {
  position: fixed;
  bottom: 5vh; /* 뷰포트 기준 하단에서 띄운 위치 */
  left: 50%;
  transform: translateX(-50%);
  padding: 1em 2em;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 8px;
  font-size: 1rem;
  max-width: 90vw; /* 모바일에서 가로 사이즈를 넘지 않게 */
  width: min(480px, 90%); /* 최대 480px까지 확대 */
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
