@import "src/assets/scss/foundations.scss";

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden; /* 필요시 사용 */

  @media (max-width: 768px) {
    padding: 16px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    align-self: flex-start; /* 부모의 align-items 속성 무시하고 본인만 왼쪽 정렬 */
    width: 100%; /* 텍스트가 제목의 전체 너비를 사용하도록 */
    text-align: left;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .school-info-container {
    background-color: #e4ecff; /* 배경색 */
    border-radius: 8px;
    padding: 16px;
    margin-top: 50px;
    margin-bottom: 50px;
    .school-info {
      font-size: 16px;
      font-weight: bold;
      color: #1d4ed8; /* 파란색 글씨 */
      margin-bottom: 8px;
    }

    .school-details {
      font-size: 14px;
      color: #6b7280; /* 회색 글씨 */
    }
  }

  .question-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .question-card {
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;

      .question-title {
        font-size: 16px;
        font-weight: bold;
        color: #111827;
        margin-bottom: 8px;
      }

      .question-content {
        font-size: 14px;
        color: #6b7280;
      }
      
      .empty-answer {
        border: 1px solid #e5e7eb;
        height: 100px;
        }
    }
  }

  .footer {
    margin-top: 40px;
    text-align: center;
    font-size: 12px;
    color: #9ca3af;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
  margin-bottom: 117px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 50px;
    padding: 0 20px;
  }

  .button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 288px;
    height: 56px;
    &.primary {
      background-color: $primary300;
      color: white;
    }

    &.secondary {
      background-color: white;
      color: $primary300;
      border: 1px solid $primary300;
    }

    @media (max-width: 768px) {
      width: 100%;
      font-size: 14px;
      padding: 10px 20px;
    }
  }
}
