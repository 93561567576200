@import "src/assets/scss/foundations.scss";

.item-list-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 768px) {
    gap: 30px;
  }

  .school-info-container {
    background-color: #e4ecff; /* 배경색 */
    border-radius: 8px;
    padding: 16px;
    .school-info {
      font-size: 16px;
      font-weight: bold;
      color: #1d4ed8; /* 파란색 글씨 */
      margin-bottom: 8px;
    }

    .school-details {
      font-size: 14px;
      color: #6b7280; /* 회색 글씨 */
    }
  }
  .admin-message-container {
    display: flex;
    align-items: flex-start; /* 아이콘과 텍스트를 수직으로 정렬 */
    background-color: $primary100; /* 배경색 */
    padding: 16px;
    border-radius: 12px;
    width: 100%;

    @media (max-width: 768px) {
      padding: 12px; /* 모바일에서는 패딩을 줄임 */
    }
  }

  .icon {
    flex-shrink: 0; /* 아이콘 영역의 크기 고정 */
    width: 40px;
    height: 40px;
    margin-right: 16px; /* 텍스트와 아이콘 사이 간격 */

    img {
      width: 100%;
      height: 100%;
    }
  }

  .message {
    flex-grow: 1; /* 메시지가 아이콘 옆에서 남은 공간을 차지 */
    font-size: 14px;
    color: #374151; /* 텍스트 색상 */

    .admin-title {
      font-weight: bold;
      font-size: 16px;
      color: $primary300; /* 타이틀 색상 */
      margin-bottom: 4px;
    }

    p {
      margin: 0;
      line-height: 1.5;
    }
  }

  .questions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 16px;
    }
  }

  .question-count {
    font-size: 18px;
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    & span {
      color: #1d4ed8;
    }
  }

  .pagination {
    display: flex;
    gap: 8px;

    @media (max-width: 768px) {
      margin-top: 30px;
      width: 100%;
      overflow-x: auto; /* 가로 스크롤 활성화 */
      -webkit-overflow-scrolling: touch; /* iOS 스크롤 부드럽게 */
    }

    .page-btn {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #f1f5f9;
      color: #6b7280;
      cursor: pointer;
      border: none;
      transition: background-color 0.3s;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */

      @media (max-width: 768px) {
        width: 35px;
        height: 35px;
      }

      &.active {
        background-color: $primary300;
        color: white;
      }
    }
  }

  .item-container {
    border: 1px solid #e5e7eb;
    border-radius: 0 20px 20px 0;
    border-left: 4px solid $primary300; /* 왼쪽 파란색 선 추가 */
    background-color: #F7F9FA;
    padding: 20px;
    transition: background-color 0.2s ease;

    .question {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      gap: 10px;

      .question-text {
        display: flex;
        flex: 1 0 0;
        color: #2D2D2D;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        align-items: center; /* 텍스트 세로 가운데 정렬 */

        .question-text-index {
          margin-right: 10px;
          display: block;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      &.answer-open {
        margin-bottom: 26px;
      }
    }


    .answer {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 8px; /* 모서리 둥글게 */
      margin-bottom: 8px;

      @media (max-width: 768px) {
        padding: 0;
        margin-top: 26px;
      }
      textarea {
        padding: 12px;
        border-radius: 4px;
        border: 1px solid #e5e7eb;
        resize: none;
        min-height: 128px;
        margin-bottom: 8px;
        background-color: #ffffff; /* 텍스트 영역 배경색 */
        color: #333; /* 텍스트 색상 */

        &:disabled {
          background-color: #e5e7eb;
          color: #9ca3af; /* 회색 텍스트 */
        }
      }

      .save-button {
        align-self: flex-end;
        padding: 8px 16px;
        color: $primary300;
        border: 1px solid $primary300;
        margin-top: 26px;
        background-color: #ffffff;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    .icon {
      width: 24px;
      height: 24px;

      @media (max-width: 768px) {
        margin-right: 0;
      }

    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
    }

    .button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      font-size: 16px;
      border-radius: 8px;
      border: 1px solid transparent;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 288px;
      height: 56px;
      &.primary {
        background-color: $primary300;
        color: white;
      }

      &.secondary {
        background-color: white;
        color: $primary300;
        border: 1px solid $primary300;
      }

      @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
        padding: 10px 20px;
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .next-button {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 20px;
      font-size: 16px;
      color: $text-weak;
      border: 1px solid $text-weak;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      width: 158px;
      height: 44px;

      @media (max-width: 768px) {
        color: $text-weak;
        padding: 10px 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
      }

      .arrow-icon {
        width: 24px;
        height: 24px;
      }
    }

    /* 버튼이 하나만 있을 때 중앙에 위치하지 않도록 각각 왼쪽, 오른쪽에 고정 */
    &.only-prev {
      justify-content: flex-start;
    }

    &.only-next {
      justify-content: flex-end;
    }
  }
}
.popup-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;

  .popup-title {
    font-size: 30px;
    font-weight: bold;
    color: #111827;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .popup-subtitle {
    font-size: 14px;
    color: #6b7280;
    text-align: left;
    margin-bottom: 24px;

    .required {
      color: #ef4444; /* 빨간색 */
    }
  }

  .popup-button {
    background-color: $primary300;
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
    margin-top: 40px;
  }

  .review-textarea {
    width: 100%;
    resize: none;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    color: #333;

    &:focus {
      border-color: #2563eb;
      outline: none;
    }

    @media (max-width: 768px) {
      height: 100px;
    }

    @media (min-width: 769px) {
      height: 160px;
    }
  }
}

.star-rating {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  .star {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    cursor: pointer;
  }
}
