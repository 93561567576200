.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  width: 100%;

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    background-color: white;
    transition: background-color 0.2s;

    /* 모바일 버전에서 텍스트 줄바꿈 처리 */
    @media (max-width: 768px) {
      padding: 16px;
    }

    &.flex-end {
      align-items: flex-end;

      /* 모바일 버전에서 텍스트 줄바꿈 처리 */
      @media (max-width: 768px) {
        flex-direction: column; /* 세로 방향으로 정렬 */
        align-items: flex-start;
      }
    }

    &:hover {
      background-color: #f1f5f9;
    }

    .text-content {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #111827;
        margin-bottom: 4px;
      }

      .subtitle {
        font-size: 14px;
        color: #6b7280;
      }
    }

    .arrow-icon {
      display: flex;
      align-items: center; /* 텍스트와 아이콘을 한 줄로 정렬 */
      cursor: pointer;

      .array_icon {
        width: 40px;
        height: 40px;
      }

      .arrow-text {
        font-size: 14px;
        margin-right: 8px;
        white-space: nowrap; /* 텍스트가 한 줄로만 표시되게 설정 */
      }

      .arrow-text-icon {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }
    }
  }
}
