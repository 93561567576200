@tailwind base;
@tailwind components;
@tailwind utilities;

.editor-styles * {
  all: revert;
}


/* 필요한 Tailwind 유틸리티를 다시 정의 */
.editor-styles .flex {
  display: flex !important;
}

.editor-styles .text-gray-500 {
  color: #6b7280 !important; /* Tailwind 색상을 원할 경우 수동으로 정의 */
}

body {
  margin: 0;
}
