@import "src/assets/scss/foundations.scss";

.container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 996px;
  margin: 0px auto;
  @media (min-width: 768px) {
    margin: 50px auto;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  margin: 20px auto;
  display: flex;
}

.user-info {
  background-color: #e4ecff;
  color: $primary300;
  margin: 10px auto;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  .sub-content {
    color: $darkToneDown;
    font-weight: normal;
    font-size: 17px;
  }
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-round-num {
  align-content: center;
  text-align: center;
  color: white;
  font-size: 20px;
  margin-left: 10px;
  width: 35px;
  height: 35px;
  background-color: #3855a5;
  border-radius: 100%;
}
.done {
  background-color: white;
  border-radius: 12px;
  text-align: center;
  width: 100%;
  max-width: 400px; // 모바일과 데스크탑 크기 반응형 조절
  margin: 0 auto;

  @media (min-width: 768px) {
    border-radius: 12px;
    max-width: 688px;
  }

  @media (min-width: 768px) {
    max-width: 600px; // 데스크탑에서의 너비
  }
  .icon {
    display: flex; /* Flexbox를 사용 */
    justify-content: center; /* 수평으로 가운데 정렬 */
    align-items: center; /* 수직으로 가운데 정렬 */
    width: 100%; /* 부모 요소에 맞게 아이콘을 중앙에 맞춤 */
    height: auto; /* 높이는 자동으로 맞추기 */
    margin-bottom: 50px; /* 아이콘과 아래 텍스트 사이 간격 */
    img {
      max-width: 50px; /* 아이콘 크기 제한 */
      height: auto; /* 아이콘의 비율을 유지하며 크기 조정 */
    }
  }

  .done-title {
    font-size: 35px;
    font-weight: bold;
    color: #111827;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 30px;
      margin-bottom: 28px;
    }
  }

  .done-subtitle {
    font-size: 16px;
    color: #6b7280;
    margin-bottom: 50px;

    @media (min-width: 768px) {
      font-size: 18px; // 데스크탑에서의 서브타이틀 크기
    }

  }
}

.round-num {
  align-content: center;
  text-align: center;
  color: #b6b6b6;
  font-size: 20px;
  margin-left: 10px;
  height: 35px;
  width: 35px;
  background-color: #ebebeb;
  border-radius: 100%;
}
.subtitle {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  line-height: 40px;
  width: 100%;
  margin: 16px auto;
  display: block;
  @media (min-width: 768px) {
    display: flex;
  }
  :last-child {
    font-size: 15px;
    font-weight: normal;
    color: $darkToneDown;
    @media (min-width: 768px) {
      margin-left: 10px;
    }
  }
}

.drop-card {
  border: #ededed 1px solid;
  margin: 30px 0;
  padding: 25px;
  border-radius: 20px;
}

.drop-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
  > img {
    height: 30px;
  }
  :first-child {
    display: flex;
  }
}
.asterisk {
  color: red;
  margin-left: 5px;
}

.form-group {
  .label {
    font-weight: bold;
    margin: 30px 0 10px 0;
  }
  .dropdown-wrapper {
    position: relative;
    display: flex;
    .input {
      border: 1px solid #ededed;
      border-radius: 10px;
      width: 100%;
      padding: 0 20px;
    }
    .search-button {
      width: 65px;
      height: 56px;
      border: 1px solid $primary300;
      color: $primary300;
      @include body('small');
      margin-left: 10px;
      border-radius: 10px;
    }
    .dropdown-menu {
      position: absolute;
      top: 60px;
      width: 100%;
      max-height: 150px;
      background-color: white;
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      overflow-y: auto;
      z-index: 10;

      li {
        padding: 10px 15px;
        font-size: 15px;
        line-height: 30px;
        cursor: pointer;
        &:hover {
          background-color: #f3f4f6;
        }
        :last-child {
          color: #9a9a9a;
        }
      }
    }
    .no-results {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      border: 1px solid #ccc;
      border-radius: 8px;
      max-height: 150px;
      text-align: center;

      img {
        width: 40px;
        margin-bottom: 8px;
      }

      span {
        font-size: 14px;
        color: #999;
      }
    }
  }
}

.select-card-detail {
  &.select {
    border: 1px solid $primary300;
  }

  border: 1px solid #ededed;
  margin: 20px 0;
  border-radius: 10px;
  padding: 20px;
  input {
    width: 24px;
    height: 24px;
    color: $primary300;
  }

  input[type="radio"] {
    accent-color: $primary300; /* 라디오 버튼의 선택 색상 */
  }

  .select-card-content {
    margin-left: 20px;
    font-weight: bold;
    color: black;
    :last-child {
      font-weight: normal;
      color: $darkToneDown;
    }
  }
}

.number {
  display: flex;
}

.drop-detail {
  display: none;
  &.active {
    display: block;
  }
}

.select-card-detail {
  align-items: center;
  display: flex;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.desktopImage {
  display: none;
}

.mobileImage {
  display: block;
  max-width: 100%;
}

@media (min-width: 768px) {
  .desktopImage {
    display: block;
    max-width: 100%;
  }

  .mobileImage {
    display: none;
  }

  .title {
    font-size: 32px;
    text-align: center;
  }
}

.button-group {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 180px;
  margin-top: 70px;
  @media (max-width: 768px) {
    margin-top: 0;
  }

  .submit-button {
    background-color: $primary300;
    color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    width: 288px;
    margin: 0 10px;

    @media (max-width: 768px) {
      width: 80%;
    }
  }

  .back-button {
    background-color: $gray100;
    border: $gray400 1px solid;
    color: $text-weak;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    width: 288px;
    margin: 0 10px;

    @media (max-width: 768px) {
      width: 20%;
    }
  }
}

.popup-container {
  padding: 20px;
  img {
    margin: 10px auto;
    width: 50px;
  }
  .popup-title {
    margin: 10px auto;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .popup-subtitle {
    margin: 10px auto 30px;
    text-align: center;
    font-size: 15px;
    color: $darkToneDown;
  }
}

.error-popup-container {
  padding: 20px;
  img {
    margin: 10px auto;
    width: 50px;
  }
  .error-popup-title {
    font-weight: bold;
    font-size: 20px;
  }
  .error-popup-subtitle {
    margin: 10px auto 30px;
    font-size: 15px;
    line-height: 25px;
    color: $darkToneDown;
  }
}
.popup-button-group {
  margin-top: 40px;
  display: flex;
  .popup-submit-button {
    background-color: $primary300;
    color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    width: 80%;
    margin-left: 10px;
  }

  .popup-back-button {
    background-color: $gray100;
    border: $gray400 1px solid;
    color: $text-weak;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    width: 20%;
  }

  .popup-button {
    background-color: $primary300;
    color: #fff;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
  }
}
