@import "src/assets/scss/foundations.scss";

.container {
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 16px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    align-self: flex-start; /* 부모의 align-items 속성 무시하고 본인만 왼쪽 정렬 */
    width: 100%; /* 텍스트가 제목의 전체 너비를 사용하도록 */
    text-align: left;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 30px;
    img {
      width: 100px;
      height: auto;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        width: 80px;
      }
    }

    p {
      font-size: 16px;
      color: #6b7280;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-around;
    gap: 24px;
    width: 100%;
    margin-top: 120px;

    @media (max-width: 768px) {
      flex-wrap: wrap; /* 카드들을 두 줄로 배치 가능하게 함 */
      gap: 0;
      margin-top: 30px;
    }
  }

  .card-list {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 16px; /* 카드 간격 설정 */

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      border: 1px solid #e5e7eb;
      border-radius: 12px;
      background-color: #fff;
      transition: background-color 0.2s ease;
      cursor: pointer;

      &:hover {
        background-color: #f1f5f9;
      }

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (min-width: 768px) {
          flex-direction: column; /* PC에서는 세로 정렬 */
          justify-content: center;
        }

        .card-header {
          font-size: 14px;
          color: #6b7280;

          @media (min-width: 768px) {
            font-size: 16px;
          }
        }

        .card-title {
          font-size: 18px;
          font-weight: bold;
          color: #111827;

          @media (min-width: 768px) {
            font-size: 20px;
          }
        }

        .card-subtitle {
          font-size: 14px;
          color: #6b7280;

          @media (min-width: 768px) {
            font-size: 16px;
          }
        }
      }

      .card-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px; /* 버튼과 아이콘 간격 */
        margin-top: 16px; /* 모바일에서 아래쪽에 공간 확보 */

        .action-button {
          padding: 10px 16px;
          font-size: 16px;
          border-radius: 50px;
          border: none;
          background-color: #d1d5db;
          color: #9B9B9B;
          cursor: pointer;
          font-weight: 400;
          line-height: normal;
          transition: background-color 0.2s ease;

          @media (max-width: 768px) {
            padding: 8px 12px;
          }

          &.completed {
            background: $primary200;
            color: #3855A5;
          }

          @media (min-width: 768px) {
            font-size: 14px;
          }
        }

        .arrow-icon {
          width: 24px;
          height: 24px;
        }

        @media (min-width: 768px) {
          justify-content: flex-end;
          align-items: center;
          margin-top: 0; /* PC에서는 카드와 같은 라인에 위치 */
          flex-direction: row; /* PC에서는 가로 정렬 */
        }

        @media (max-width: 768px) {
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .footer {
    margin-top: 40px;
    text-align: center;
    font-size: 12px;
    color: #9ca3af;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
}

.admin-message-container {
  display: flex;
  align-items: flex-start; /* 아이콘과 텍스트를 수직으로 정렬 */
  background-color: $primary100; /* 배경색 */
  padding: 16px;
  border-radius: 12px;
  width: 100%;
  margin-top: 50px;

  @media (max-width: 768px) {
    padding: 12px; /* 모바일에서는 패딩을 줄임 */
  }
}

.icon {
  flex-shrink: 0; /* 아이콘 영역의 크기 고정 */
  width: 40px;
  height: 40px;
  margin-right: 16px; /* 텍스트와 아이콘 사이 간격 */

  img {
    width: 100%;
    height: 100%;
  }
}

.message {
  flex-grow: 1; /* 메시지가 아이콘 옆에서 남은 공간을 차지 */
  font-size: 14px;
  color: #374151; /* 텍스트 색상 */

  .admin-title {
    font-weight: bold;
    font-size: 16px;
    color: $primary300; /* 타이틀 색상 */
    margin-bottom: 4px;
  }

  p {
    margin: 0;
    line-height: 1.5;
  }
}
