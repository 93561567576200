@import "src/assets/scss/foundations.scss";

.popup-container {
  display: flex;
  width: 100%; flex-direction: column;
  align-items: center;
  justify-content: center;
  .buttonRow {
    display: flex;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    
    .button {
      flex: 1;
      padding: 15px 0;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      border: none;
      }
    
    .popup-button-one-day {
      color: $text-weak;
      }
    }
  
  }
