@import "src/assets/scss/foundations.scss";

.container {
  width: 100%;
  max-width: 1000px; // PC 화면에서의 최대 너비
  margin: 0 auto;
  height: 100vh;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 40px 16px 16px 16px;
  }

  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 73px;

    .icon {
      width: 80px;
      height: 80px;
      margin-bottom: 16px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .message {
      font-size: 16px;
      color: #6b7280;
    }
  }

  @media (max-width: 768px) {
    .no-data {
      margin-top: 22px;
    }
    .icon {
      width: 65px;
      height: 65px;
    }

    .message {
      font-size: 14px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      display: block;
      padding-bottom: 50px;
      margin-bottom: 0;
    }

    .title {
      font-size: 24px;
      font-weight: bold;

      @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 30px; /* 모바일에서는 제목 아래에 공간 추가 */
      }
    }

    .search-bar {
      display: flex;
      align-items: center;
      border: 1px solid #f5f5f5;
      padding: 12px 16px;
      border-radius: 8px;
      width: 378px; /* 검색창 너비 조정 */

      input {
        border: none;
        background-color: transparent;
        flex-grow: 1;
        padding-left: 8px;
        font-size: 16px;
        color: #6b7280;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #a0aec0;
        }
      }

      .search-icon {
        width: 24px;
        height: 24px;
        color: #a0aec0;
      }

      @media (max-width: 768px) {
        width: 100%; /* 모바일에서 검색창 너비 100% */
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background-color: #fff;
      border-bottom: 1px solid #e5e7eb; /* 기본적으로 아래쪽 border만 표시 */

      &:first-child {
        border-top: 1px solid #e5e7eb; /* 첫 번째 항목의 위쪽 border 추가 */
      }

      &:last-child {
        border-bottom: 1px solid #e5e7eb;
      }

      .left {
        display: flex;
        align-items: center;
        flex-direction: row; /* PC에서는 같은 줄에 배치 */
        gap: 12px;

        @media (max-width: 768px) {
          flex-direction: column; /* 모바일에서는 배지를 위로 */
          align-items: flex-start;
          gap: 0;
        }

        .badge-container {
          width: 80px;
          .badge {
            @include body("xssmall");
            background-color: #e5e7eb;
            padding: 4px 8px;
            border-radius: 5px;
            color: #6b7280;
            text-align: center;
            display: inline-block; // 텍스트 크기에 맞게 배지의 크기 조정

            @media (max-width: 768px) {
              margin-bottom: 10px; /* 모바일에서는 배지와 텍스트 사이의 간격 */
            }
          }
        }


        .content {
          display: flex;
          flex-direction: column;

          .message {
            font-size: 14px;
            color: #111827;
            margin-bottom: 4px;

            &.bold {
              font-weight: bold; /* 조건에 따라 글씨 굵게 */
            }
          }

          .date {
            font-size: 12px;
            color: #6b7280;
          }
        }
      }

      .arrow {
        width: 24px;
        height: 24px;
        color: $darkDefault;

        @media (max-width: 768px) {
          display: none; /* 모바일에서는 숨김 */
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 50px;

    .page-button {
      background-color: #fff;
      border: 1px solid #e5e7eb;
      padding: 8px 12px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 14px;
      color: #6b7280;

      &:hover {
        background-color: #f5f5f5;
      }

      &.disabled {
        background-color: #f0f0f0;
        color: #a0aec0;
        cursor: not-allowed;
      }
    }
  }
}
