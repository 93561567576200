@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css");

// Funble Design System (ver.1)

// COLORS ////////////////////////////////////////

// Foundation Palette
// primary
$primary100: #f6f9ff;
$primary200: #e4ecff;
$primary300: #3855a5;

// dark
$darkDefault: #2d2d2d;
$darkToneDown: #626262;

// secondary
$secondary100: #e4e8f1;
$secondary200: #c8d0dd;
$secondary300: #b1bed5;
$secondary400: #8897b1;
$secondary500: #768399;
$secondary600: #545e6f;
$secondary700: #48505d;

// thirdly
$thirdly1100: #e5f2ff;
$thirdly1200: #4091e2;
$thirdly1300: #216db9;

$thirdly2100: #ffe7ea;
$thirdly2200: #f5586b;
$thirdly2300: #d63d4f;

// gray
$gray100: #ffffff;
$gray200: #f5f7f9;
$gray300: #eff2f5;
$gray400: #dcdfe3;
$gray500: #c3c5ca;
$gray600: #676A6E;
$gray700: #676a6e;
$gray800: #393a3c;
$gray900: #191a1a;

// stateful
$success: #216db9;
$error: #d63d4f;

// Text Palette
// brand
$text-brand: #21b8c2;

// base
$text-default: #191a1a;
$text-weak: #676a6e;
$text-placeholder: #a0a4aa;
$text-white: #ffffff;

// stateful
$text-success: #0075ff;
$text-error: #d63d4f;

$red500: #e44a4a;

// Helper function to map color values to their keys
$color-palettes: (
  "primary": (
    100: $primary100,
    200: $primary200,
    300: $primary300,
  ),
  "secondary": (
    100: $secondary100,
    200: $secondary200,
    300: $secondary300,
    400: $secondary400,
    500: $secondary500,
    600: $secondary600,
    700: $secondary700,
  ),
  "thirdly1": (
    100: $thirdly1100,
    200: $thirdly1200,
    300: $thirdly1300,
  ),
  "thirdly2": (
    100: $thirdly2100,
    200: $thirdly2200,
    300: $thirdly2300,
  ),
  "gray": (
    100: $gray100,
    200: $gray200,
    300: $gray300,
    400: $gray400,
    500: $gray500,
    600: $gray600,
    700: $gray700,
    800: $gray800,
    900: $gray900,
  ),
  "dark": (
    100: $darkDefault,
    50: $darkToneDown,
  ),
  "red": (
    500: $red500,
  ),
  "base": (
    gray: $text-weak,
  ),
);

// Mixin to generate classes for each color level
@mixin generate-color-classes {
  @each $palette-name, $colors in $color-palettes {
    @each $level, $color in $colors {
      .text-#{$palette-name}-#{$level} {
        color: $color;
      }

      .bg-#{$palette-name}-#{$level} {
        background-color: $color;
      }

      .border-#{$palette-name}-#{$level} {
        border-color: $color;
      }
    }
  }
}

// Use the mixin to generate all color-related classes
@include generate-color-classes;

// TYPOGRAPHY ////////////////////////////////////////

// @include heading;
@mixin heading($scale: "") {
  font-family: "Pretendard";
  font-weight: 700;

  @if $scale == "xlarge" {
    font-size: 30px;
    line-height: 40px;
  } @else if $scale == "large" {
    font-size: 24px;
    line-height: 30px;
  } @else if $scale == "small" {
    font-size: 18px;
    line-height: 24px;
  } @else {
    font-size: 20px;
    line-height: 26px;
  }
}

// @include body;
@mixin body($scale: "", $highlight: false) {
  font-family: "Pretendard";
  line-height: 24px;
  font-weight: bold;

  @if $scale == "large" {
    font-size: 18px;
  } @else if $scale == "medium" {
    font-size: 16px;
  } @else if $scale == "medium-notbold" {
    font-size: 16px;
    font-weight: normal;
  } @else if $scale == "small" {
    font-size: 14px;
  } @else if $scale == "small-notbold" {
    font-size: 14px;
    font-weight: normal;
  } @else if $scale == "xssmall" {
    font-size: 13px;
    line-height: 20px;
    font-weight: normal;
  }
}

// @include label;
@mixin label {
  font-family: "Pretendard";
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

// SPACING ////////////////////////////////////////
$spacing100: 2px;
$spacing200: 4px;
$spacing300: 8px;
$spacing400: 12px;
$spacing500: 20px;

// ICON ////////////////////////////////////////
@mixin icon($size: 44, $padding: 20) {
  width: $size;
  height: $size;
  padding: $padding;

  > img,
  > svg {
    width: 100%;
    height: 100%;
  }
}

// BORDER RADIUS ////////////////////////////////////////
$radius100: 4px;
$radius200: 8px;
$radius300: 12px;
$radius400: 16px;
$radius500: 20px;
$circle: 100px;

@mixin radius($weight: 400) {
  @if $weight == 100 {
    border-radius: $radius100;
  } @else if $weight == 200 {
    border-radius: $radius200;
  } @else if $weight == 300 {
    border-radius: $radius300;
  } @else if $weight == 400 {
    border-radius: $radius400;
  } @else if $weight == 500 {
    border-radius: $radius500;
  } @else {
    border-radius: $circle;
  }
}

// EVALUATION ////////////////////////////////////////
$shadow100: 2px 2px 10px 0px rgba(186, 189, 192, 0.2),
  0px 5px 30px 0px rgba(225, 227, 229, 0.56);
$shadow200: 2px 2px 5px 0px rgba(215, 224, 224, 0.16),
  0px 2px 15px 0px rgba(242, 246, 246, 0.18);
$shadow300: 2px 2px 5px 0px rgba(186, 189, 192, 0.2),
  0px 5px 10px 0px rgba(225, 227, 229, 0.56);

@mixin evaluation($weight: 200) {
  @if $weight == 100 {
    box-shadow: $shadow100;
  } @else if $weight == 200 {
    background-color: $gray100;
    border: 1px solid $gray300;
    box-shadow: $shadow200;
  } @else if $weight == 300 {
    box-shadow: $shadow300;
  }
}

// ANIMATION ////////////////////////////////////////
.auto-rolling-y {
  animation-fill-mode: forwards;
  animation-name: AutoRollingVertical;
}

.auto-rolling-x {
  animation-fill-mode: forwards;
  animation-name: AutoRollingHorizontal;
}

// KEYFRAMES ////////////////////////////////////////

@keyframes AutoRollingVertical {
  from {
    transform: none;
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes AutoRollingHorizontal {
  from {
    transform: none;
  }

  to {
    transform: translateX(-100%);
  }
}

// COMMON ////////////////////////////////////////
@mixin line-clamp($line: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  line-clamp: $line;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.funble-list-container {
  > :first-child {
    margin-top: 0 !important;
    border-top: 0 none !important;
  }

  > :last-child {
    margin-bottom: 0 !important;
    border-bottom: 0 none !important;
  }

  &.with-gap {
    display: flex;
    flex-direction: column;
  }

  li.funble-list__item {
    &.border {
      border-bottom: 1px solid $gray300;
    }

    > div {
      display: flex;
      flex-direction: row;

      .sub-text {
        @include body("large");
        color: $text-weak;
      }
      .main-text {
        @include body("large", true);
        color: $text-default;
      }
    }
  }
}

body {
  font-family: "Pretendard", sans-serif;
  -webkit-font-smoothing: antialiased; // 웹킷 기반 브라우저에서 폰트 부드럽게
  -moz-osx-font-smoothing: grayscale; // macOS에서 그레이스케일로 폰트 부드럽게
}

// 큰 텍스트
.large-text {
  @include body("large");
}

// 중간 텍스트
.medium-text {
  @include body("medium");
}

// 중간 텍스트
.medium-notbold-text {
  @include body("medium-notbold");
}

// 작은 텍스트
.small-text {
  @include body("small");
}

// 작은 텍스트 (굵기 없음)
.small-notbold-text {
  @include body("small-notbold");
}

// 매우 작은 텍스트
.xssmall-text {
  @include body("xssmall");
}

// 하이라이트 적용 (클래스 예시)
.highlighted {
  @include body("large", true); // 크기 large + 하이라이트 적용
}

#body {
  position: relative; /* Toast의 부모 요소로 설정 */
  min-height: 100vh;
}

