@import "src/assets/scss/foundations.scss";

.footer {
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* 큰 해상도에서 가운데 정렬을 위한 max-width */
  @media (min-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px; /* 해상도에 맞게 중앙에 고정 */
  }


  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;
    font-size: 14px;
    color: #6b7280;
    line-height: 1.5;

    .content-gap {
      gap: 18px;
    }

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .left {
      @include body("medium");
      display: flex;
      gap: 26px;

      @media (max-width: 768px) {
        gap: 48px;
      }

      .link {
        color: #111827;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .right {
      @include body("xssmall");
      flex-wrap: wrap;
      color: #757575;
      @media (max-width: 768px) {
        .customer-service {
          display: block;
          flex-wrap: wrap;
          width: 100%; /* 모바일에서만 고객센터 정보를 새로운 줄로 이동시킴 */
        }
      }
    }
  }

  .icons {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    justify-content: flex-start; /* 모바일에서는 왼쪽 정렬 */

    .icon {
      width: 44px; /* icons의 크기를 44px로 설정 */
      height: 44px; /* icons의 크기를 44px로 설정 */
      border-radius: 50%;
      background-color: #d1d5db;
      display: flex; /* 이미지 가운데 정렬을 위한 flex 설정 */
      align-items: center;
      justify-content: center;

      img {
        width: 22px;
        height: 22px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      justify-content: left; /* 데스크탑 사이즈에서는 중앙 정렬 */
      margin-top: 0;
    }
  }
}
