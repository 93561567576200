@import "src/assets/scss/foundations.scss";

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* 필요시 사용 */

  @media (max-width: 768px) {
    padding: 16px;
    height: auto;
  }

  .admin-message-container {
    display: flex;
    align-items: flex-start; /* 아이콘과 텍스트를 수직으로 정렬 */
    background-color: $primary100; /* 배경색 */
    padding: 16px;
    border-radius: 12px;
    width: 100%;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      padding: 12px; /* 모바일에서는 패딩을 줄임 */
      margin-bottom: 30px;
      align-items: center; /* 아이콘과 텍스트를 수직으로 정렬 */
    }
  }

  .icon {
    flex-shrink: 0; /* 아이콘 영역의 크기 고정 */
    width: 18px;
    height: 18px;
    margin-right: 16px; /* 텍스트와 아이콘 사이 간격 */

    img {
      width: 100%;
      height: 100%;
    }
  }

  .message {
    flex-grow: 1; /* 메시지가 아이콘 옆에서 남은 공간을 차지 */
    font-size: 14px;
    color: #374151; /* 텍스트 색상 */

    .admin-title {
      font-weight: bold;
      font-size: 16px;
      color: $primary300; /* 타이틀 색상 */
      margin-bottom: 4px;
    }

    p {
      margin: 0;
      line-height: 1.5;
    }
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
    align-self: flex-start; /* 부모의 align-items 속성 무시하고 본인만 왼쪽 정렬 */
    width: 100%; /* 텍스트가 제목의 전체 너비를 사용하도록 */
    text-align: left;
    margin-bottom: 50px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .centered-container {
    display: flex;
    padding: 30px;
    background-color: #E4ECFF; /* 배경 색상 */
    border-radius: 20px; /* 모서리 둥글게 */

    .content {
      font-size: 20px;
      color: $primary300; /* 파란색 글씨 */
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }

  .uploadSection {
    margin-bottom: 40px;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 14px;
      color: #6b7280;
      margin-bottom: 16px;
    }

    .fileInputContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 768px) {
        flex-direction: column; /* 모바일에서 수직 배치 */
        align-items: stretch; /* 수직으로 늘어남 */
      }

      .fileNameInput {
        width: 100%;
        height: 56px;
        padding: 16px;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        margin-right: 16px;
        background-color: #f9fafb;
        font-size: 14px;
        color: #6b7280;

        @media (max-width: 768px) {
          margin-right: 0;
          margin-bottom: 10px; /* 업로드 버튼과의 간격 */
        }
      }

      .uploadButton {
        color: $primary300;
        @include body("small");
        width: 160px;
        height: 56px;
        border-radius: 8px;
        border: 1px solid $primary300;
        cursor: pointer;
        display: inline-flex;
        padding: 8px 15px;
        align-items: center;
        justify-content: right; /* 아이콘과 텍스트 가운데 정렬 */

        .icon {
          width: 16px;
          height: 16px;
          margin-left: 10px;
        }
      }

      .hiddenFileInput {
        display: none;
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 8px;
      cursor: pointer;
      width: 287px;

      &.cancel {
        background-color: transparent;
        border: 1px solid #d1d5db;
        color: #6b7280;
        margin-right: 20px;

        &:hover {
          background-color: #f9fafb;
        }
      }

      &.confirm {
        background-color: $primary300;
        color: #fff;
        border: none;
      }

      &:disabled {
        background-color: #e5e7eb;
        color: #9ca3af; /* 회색 텍스트 */
      }
    }

    @media (max-width: 768px) {
      gap: 10px;

      .button {
        width: 100%;
        text-align: center;

        &.cancel {
          background-color: transparent;
          border: 1px solid #d1d5db;
          color: #6b7280;
          width: 88px;
          margin-right: 0;

          &:hover {
            background-color: #f9fafb;
          }
        }

        &.confirm {
          background-color: $primary300;
          color: #fff;
          border: none;
          width: 227px;
        }
      }
    }
  }
}

.agreement-container {
  margin-bottom: 40px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;

  .agreement-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .agreement-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 0;

    @media (max-width: 765px) {
      padding: 10px 0;
    }

    .checkbox-wrapper {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .checkbox {
        appearance: none;
        width: 20px; /* 체크박스 크기 */
        height: 20px; /* 체크박스 크기 */
        border: 2px solid #d1d5db;
        border-radius: 50%;
        margin-right: 12px;
        margin-top: 4px; /* 라벨과의 수직 맞춤 */
        cursor: pointer;
        position: relative;

        @media (max-width: 765px) {
          margin-right: 10px;
        }

        &:checked {
          background-color: $primary300;
          border-color: $primary300;
        }

        &:checked::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background-color: white;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .checkbox-label {
        font-size: 14px;
        color: #626262;
        line-height: 24px;
        font-weight: 600;
        word-wrap: break-word;
        max-width: calc(100% - 50px); /* 체크박스 옆의 라벨 공간 */
      }
    }

    .link {
      font-size: 14px;
      color: #1d4ed8;
      cursor: pointer;
      align-self: flex-start; /* 링크의 위치를 상단에 맞춤 */
    }

    .arrow-text-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;

      @media (max-width: 765px) {
        margin-right: 10px;
      }

      .arrow-text {
        font-size: 14px;
        color: #626262;
        line-height: 24px;
        font-weight: 600;
        margin-right: 8px;
        white-space: nowrap; /* 텍스트가 한 줄로만 표시되게 설정 */
      }

      .arrow-text-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.popupContainer {
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  max-height: 500px;
  overflow: scroll;
  gap: 20px;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }

  .popupTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .popupText {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: $text-weak;
  }

  .popupList {
    margin-top: 20px;

    .listTitle {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .listItem {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 8px;
      margin-left: 20px;
      color: $text-weak;
    }
  }
}

.popupFooter {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    background-color: $primary300;
    width: 100%;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
}
