.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: auto;
  background-color: #f8fafc;

  .container {
    margin-top: 80px;
    margin-bottom: 402px;

    text-align: center;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    width: 488px;

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 12px;
      text-align: left; /* 왼쪽 정렬 */
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      .puzzle-image {
        max-width: 100%;
        height: auto;
        display: block;
      }

      .puzzle-image-mo {
        display: none;
      }
    }

    .kakao-button {
      border: none;
      cursor: pointer;

      .kakao-login {
        max-width: 100%;
        height: auto;
        display: block;
      }

      .kakao-login-mo {
        display: none;
      }

      img {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

@media (max-width: 768px) {
  .login-page {
    background-color: #ffffff;
    width: 100%;
    justify-content: center;
    align-items: flex-start;

    .container {
      padding: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
      .title {
        font-size: 20px;
      }

      .image-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        .puzzle-image {
          display: none;
        }

        .puzzle-image-mo {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }

      .kakao-button {
        .kakao-login {
          display: none;
        }

        .kakao-login-mo {
          max-width: 100%;
          height: auto;
          display: block;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }
}
