@import "src/assets/scss/foundations.scss";

.container {
  max-width: 800px; /* 콘텐츠의 최대 너비를 조정 */
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 16px;
    max-width: 100%;
  }

  .header {
    display: flex;
    flex-direction: column; /* 배지를 타이틀 위로 올리기 위해 수직 정렬 */
    gap: 4px; /* badge와 타이틀 사이의 간격 */
    margin-bottom: 16px;

    .badge {
      background-color: #e5e7eb;
      height: 28px;
      padding: 4px 8px;
      border-radius: 8px;
      @include body("xssmall");
      color: #7b7b7b;
      align-self: flex-start; /* 배지가 왼쪽에 정렬되도록 설정 */
    }

    .title-content {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 4px; /* 타이틀과 날짜 사이의 간격 */

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      .date {
        font-size: 14px;
        color: #6b7280;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }

  .content {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 24px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    img {
      max-width: 100%;
      height: auto; /* 이미지가 비율을 유지하며 화면에 맞게 조정 */
      border-radius: 12px;
      margin: 24px 0; /* 이미지와 텍스트 사이에 간격 추가 */
    }
  }

  .button-wrapper {
    text-align: center;
    margin-top: 50px;

    .button {
      @include body("large");
      padding: 8px 20px;
      color: $primary300;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid $primary300;

      @media (max-width: 768px) {
        @include body("xssmall");
        font-weight: bold;
        width: 65px;
        height: 40px;
      }
    }
  }
}

.divider {
  width: 100%; /* 100% 너비 */
  height: 2px; /* 2px 높이 */
  background-color: #f5f7f9; /* 파란색 (#1d4ed8) */
  margin-bottom: 34px;
  display: block;

  @media (max-width: 768px) {
    display: block;
  }
}
