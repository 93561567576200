.instructions-container {
  padding-top: 72px;
  @media (max-width: 768px) {
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .gap-container {
    gap: 72px;

    @media (max-width: 768px) {
      gap: 54px;
    }

    .image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 115px; /* 메뉴와 이미지 사이의 간격을 조정 */

      .tab-image {
        width: 100%;
        max-width: 720px;
        height: auto;
        object-fit: contain;
        display: block;
      }

      @media (min-width: 768px) {
        .tab-image-mo {
          display: none;
        }
      }

      @media (max-width: 768px) {
        gap: 55px;
        .tab-image {
          display: none;
        }

        .tab-image-mo {
          display: block;
          max-width: 335px; /* 작은 화면에서는 335px로 크기 조정 */
        }
      }
      .card-container {
        width: 100%;
        max-width: 720px;
        @media (max-width: 768px) {
          max-width: 335px; /* 작은 화면에서는 335px로 크기 조정 */
        }
      }
    }
  }
}

.text-center{
  text-align: center;

  .inline-text {
    display: flex;
    flex-direction: row;
    align-items: center; // 수직 정렬
    justify-content: center; // 가로 가운데 정렬 (필요시)
  }
}
