@import "src/assets/scss/foundations.scss";
.header{
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
}
.header-container {
  display: flex;
  justify-content: space-between; /* 로고와 메뉴를 양쪽으로 정렬 */
  align-items: center;
  padding: 20px 40px;
  background-color: white;

  /* 큰 해상도에서 가운데 정렬을 위한 max-width */
  @media (min-width: 1200px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px; /* 해상도에 맞게 중앙에 고정 */
  }

  .logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: $darkDefault;
    margin-right: 78px; /* 로고와 메뉴 사이 간격을 직접 조정 */
    width: 146px;
    height: 36px;

    @media (max-width: 800px) {
      position: absolute;
      width: 107px;
      height: 25px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 10px;
    }
  }

  .flag-icon {
    width: 24px;
    height: 24px;
  }
  .gnb-menu {
    @include body("large");

    display: flex;
    align-items: center;
    gap: 35px; /* 메뉴 항목 간의 간격 */
    list-style: none;
    padding: 0;
    margin: 0;

    .menu-item {
      position: relative; /* 드롭다운 구현을 위한 설정 */
      cursor: pointer;
      padding: 16px 20px;
      height: 56px;

      @media (min-width: 800px) and (max-width: 850px) {
        padding: 6px;
      }

      @media (min-width: 850px) and (max-width: 950px) {
        padding: 8px 10px;
      }

      border-radius: 12px;

      &:hover {
        background-color: #EEF4FB; /* 마우스 오버 시 파란색 */
      }

      /* 드롭다운 메뉴 */
      .dropdown-menu {
        display: none;
        position: absolute;
        top: 100%; /* 부모 아래에 표시 */
        left: 0;
        background-color: white;
        border: 1px solid #e5e7eb;
        border-radius: 10px;
        z-index: 1000;
        padding: 10px;
        white-space: nowrap;
        flex-direction: column;
        box-shadow: 0px 4px 4px 0px rgba(191, 195, 206, 0.29);
        width: 180px;

        .dropdown-item {
          padding: 10px 20px;
          font-size: 14px;
          color: #2D2D2D;
          font-weight: 500;

          &:hover {
            background-color: #f1f5f9;
          }
        }
      }

      .active {
        display: block;
      }
    }
  }

  .login {
    margin-left: auto; /* 로그인 버튼을 오른쪽으로 정렬 */
    font-size: 16px;
    font-weight: bold;
    color: $primary300;
    padding: 8px 20px;
    border: 2px solid $primary300;
    border-radius: 8px;
    cursor: pointer;

    @media (max-width: 800px) {
      padding: 3.5px 7px;
    }

    @media (min-width: 800px) {
      &:hover {
        background-color: $primary300;
        color: #fff;
      }
    }
  }

  .profile-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto; /* 로그인 버튼을 오른쪽으로 정렬 */

    .profile-icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 800px) {
        width: 30px;
        height: 30px;
      }
    }

    .dropdown {
      position: absolute;
      top: 40px;
      right: 0;
      background-color: white;
      box-shadow: 0px 4px 4px 0px rgba(191, 195, 206, 0.29);
      border-radius: 8px;
      display: none;
      flex-direction: column;
      width: 150px;
      padding: 10px 0;
      z-index: 1000;

      &.active {
        display: flex;
      }

      .dropdown-item {
        padding: 12px 16px;
        font-size: 14px;
        color: #111827;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #f1f5f9;
        }
      }
    }
  }
}

/* PC 전용 스타일 */
@media (min-width: 800px) {
  .header-container {
    .gnb-menu {
      display: flex;
    }

    .mobile-menu {
      display: none;
    }

    .menu-icon {
      display: none;
    }
  }
}

/* 모바일 전용 스타일 */
@media (max-width: 800px) {
  /* 회색 배경 오버레이 추가 */
  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 어두운 반투명 배경 */
    z-index: 999;

    &.active {
      display: block; /* 메뉴가 열리면 배경이 활성화됨 */
    }
  }

  .header-container {
    padding: 10px 20px;

    .logo {
      justify-content: center;
    }

    .gnb-menu {
      display: none;
    }

    .menu-icon {
      display: block;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .menu-icon-bar {
      width: 100%;
      height: 3px;
      background-color: #1f2937; /* 아이콘 색상 */
      border-radius: 5px;
    }

    .mobile-menu {
      display: none;

      @media (max-width: 768px) {
        &.active {
          display: flex;
          position: fixed; /* 화면 전체를 덮음 */
          top: 0;
          left: 0;
          width: 80%;
          height: 100%; /* 화면 전체 높이 */
          background-color: white;
          flex-direction: column;
          padding: 20px;
          z-index: 1000;
          box-shadow: 0px 4px 4px 0px rgba(191, 195, 206, 0.29);
        }
        .close-icon {
          align-self: flex-start;
          font-size: 24px;
          cursor: pointer;
          margin-bottom: 20px; /* 닫기 아이콘 아래에 간격 추가 */
        }

        .menu-item {
          @include body("large");
          padding: 16px 10px; /* 메뉴 항목 사이 여백을 줄임 */
          margin: 0 0 12px 0; /* 메뉴 항목들이 더 붙도록 설정 */

          &:last-child {
            border-bottom: none;
            margin: 0 0 0 0; /* 메뉴 항목들이 더 붙도록 설정 */
          }

          &:hover {
            color: #3b82f6;
          }

          &.dropdown {
            cursor: pointer;

            .dropdown-menu {
              display: none;
              flex-direction: column;
              margin-top: 16px;
              padding-left: 10px;

              &.active {
                display: block;
              }

              .dropdown-item {
                padding: 8px 0; /* 서브메뉴 여백도 줄임 */
                font-size: 16px;
                color: #6b7280;
              }
            }
          }
        }
      }
    }
  }
}

.space-between {
  justify-content: space-between;

  /* PC 전용 스타일 */
  @media (min-width: 800px) {
    gap: 10px;
  }
}
