@import "src/assets/scss/foundations.scss";

.form-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #EDEDED;

  @media (max-width: 768px) {
    padding: 30px;
  }

  .form-group {
    margin-bottom: 24px;

    .label {
      @include body('medium');
      margin-bottom: 8px;
      display: block;

      .required {
        color: #ef4444; /* 빨간색 */
        margin-left: 4px;
      }
    }

    .input {
      width: 100%;
      padding: 12px;
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      font-size: 16px;
      color: #374151;
      transition: border-color 0.3s ease, background-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #2563eb;
        background-color: #ffffff;
      }
    }

    .custom-select {
      position: relative;
      display: inline-block;
      width: 100%;

      select {
        width: 100%;
        height: 40px;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        cursor: pointer;
        background-color: #fff;
      }

      &::after {
        position: absolute;
        top: 14px;
        right: 16px;
        pointer-events: none;
        font-size: 12px;
        color: #6b7280;
      }
    }

    .dropdown-wrapper {
      position: relative;
      display: flex;
      align-items: center;

      /* 모바일 화면에서 검색 버튼을 아래로 */
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .input-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;

        .input {
          height: 56px;
          padding: 16px;
          font-size: 14px;
          border: 1px solid #e5e7eb;
          border-radius: 12px;
          box-sizing: border-box;
        }

        .down-icon {
          position: absolute;
          right: 8px;
          width: 24px;
          height: 24px;
        }
      }
      .input {
        width: calc(100% - 66px);
        height: 50px;
        padding: 16px;
        font-size: 14px;
        border: 1px solid #e5e7eb;
        border-radius: 12px;
        box-sizing: border-box;

        /* 모바일 화면에서 검색 버튼을 아래로 */
        @media (max-width: 768px) {
          width: 100%;
        }

      }

      .input-year {
        width: 100%;
        height: 50px;
        padding: 16px;
        font-size: 14px;
        border: 1px solid #e5e7eb;
        border-radius: 12px;
        box-sizing: border-box;
      }

      .search-button {
        width: 66px;
        height: 50px;
        justify-content: center;
        align-items: center;
        border: 1px solid $primary300;
        color: $primary300;
        border-radius: 8px;
        cursor: pointer;
        font-size: 14px;
        margin-left: 10px;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-top: 10px;
          height: 56px;
          padding: 8px 20px;
          white-space: nowrap;
        }

        &:hover {
          background-color: $primary300; /* 마우스 오버 시 파란색 */
          color: #ffffff;
        }
      }

      .dropdown-menu {
        margin-top: 20px;
        position: absolute;
        top: 44px;
        width: 100%;
        background-color: white;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        overflow-y: auto;
        z-index: 10;
        box-shadow: 0px 4px 4px 0px rgba(191, 195, 206, 0.29), 0px 4px 16px 0px rgba(197, 201, 209, 0.25);


        li {
          padding: 8px;
          font-size: 14px;
          cursor: pointer;

          .school-info {
            .school-type {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 171.429% */
            }

            .school-address {
              color: #9A9A9A;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 184.615% */
            }
          }


          &:hover {
            background-color: #f3f4f6;
          }
        }
      }

      .no-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-radius: 8px;
        text-align: center;
        height: 216px;

        @media (max-width: 768px) {
          height: 167px;
        }


        img {
          width: 40px;
          margin-bottom: 8px;
        }

        span {
          font-size: 14px;
          color: #999;
        }
      }
      @media (max-width: 768px) {
        .high-scroll-search {
          top: 116px;
          margin-top: 16px;
        }
      }
    }

    .radio-group {
      display: flex;
      gap: 20px;
      white-space: nowrap; /* 텍스트를 한 줄로 만듦 */
      flex-wrap: wrap; /* 줄 바꿈을 허용 */

      label {
        display: flex;
        align-items: center;
        gap: 6px;

        input[type="radio"] {
          accent-color: $primary300; /* 라디오 버튼의 선택 색상 */
        }

        font-size: 14px;
        color: #374151;
      }
    }

    @media (max-width: 768px) {
      margin-top: 0.5rem;
      width: 100%;
    }
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;

    @media (max-width: 768px) {
      gap: 16px;
    }

    .cancel-button {
      border-radius: 12px;
      border: 1px solid #DCDFE3;
      background-color: #ffffff;
      color: #374151;
      padding: 16px 30px;
      font-size: 14px;
      cursor: pointer;
      width: 287px;

      @media (max-width: 768px) {
        display: flex;
        padding: 16px 30px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        width: 88px;
        white-space: nowrap;
      }
    }

    .submit-button {
      background-color: $primary300;
      color: #fff;
      padding: 16px 30px;
      border-radius: 12px;
      font-size: 14px;
      cursor: pointer;
      width: 287px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  & .alone {
    justify-content: center;
  }
}


.action-buttons {
  display: flex;
  justify-content: space-around;
  gap: 24px;
  width: 100%;
  margin-top: 120px;

  @media (max-width: 768px) {
    flex-wrap: wrap; /* 카드들을 두 줄로 배치 가능하게 함 */
    gap: 0;
    margin-top: 30px;

    .br-tag {
      display: block;
    }
  }

  .br-tag {
    display: none;
  }
}
