@import "src/assets/scss/foundations.scss";

.container {
  max-width: 916px;
  margin: 0 auto;
  padding-top: 40px;
  height: auto;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 16px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .admin-message-container {
    display: flex;
    align-items: center; /* 아이콘과 텍스트를 수직으로 정렬 */
    background-color: $primary100; /* 배경색 */
    padding: 16px;
    border-radius: 12px;
    width: 100%;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      padding: 12px; /* 모바일에서는 패딩을 줄임 */
    }
  }

  .icon {
    flex-shrink: 0; /* 아이콘 영역의 크기 고정 */
    width: 18px;
    height: 18px;
    margin-right: 16px; /* 텍스트와 아이콘 사이 간격 */

    img {
      width: 100%;
      height: 100%;
    }
  }

  .message {
    flex-grow: 1; /* 메시지가 아이콘 옆에서 남은 공간을 차지 */
    font-size: 14px;
    color: #374151; /* 텍스트 색상 */

    p {
      margin: 0;
      line-height: 1.5;
    }
  }

  .upload-section-wrapper {
    min-height: 423px;

    @media (max-width: 768px) {
      min-height: 0;
    }

    .upload-section {
      display: flex;
      flex-direction: column;
      border: 1px solid #e5e7eb;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 24px;

      @media (max-width: 768px) {
        padding: 30px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        justify-items: center;
        font-size: 16px;
        margin-bottom: 16px;
        width: 100%;

        .header-title {
          display: flex;
          justify-content: flex-start; /* 왼쪽에 span, 오른쪽에 버튼 */
          align-items: center; /* 수직 가운데 정렬 */
          justify-items: center;
          width: 100%; /* 가로 너비 전체 */

          > span {
            font-size: 20px;
            font-weight: bold;

            @media (max-width: 768px) {
              font-size: 18px;
              font-weight: 700;
              line-height: normal;
            }
          }

          @media (max-width: 768px) {
            margin-bottom: 40px;

            &.file-yn {
              margin-bottom: 0;
            }
          }

          .close-button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 24px;
            width: 24px;
            height: 24px;
            line-height: 1;
            color: #6b7280;

            @media (max-width: 768px) {
              margin-bottom: 20px;
            }

            img {
              width: 11px;
              height: 11px;
            }

            &:hover {
              color: #111827;
            }
          }

          @media (max-width: 768px) {
            flex-direction: column; /* 모바일에서 버튼을 세로로 배치 */
            justify-self: flex-start;
            align-items: flex-start;
            width: 100%;
          }
        }
      }

      .file-info {
        display: flex;
        margin-bottom: 16px;
        background-color: #f9f9f9;
        padding: 16px;
        border-radius: 10px;

        @media (max-width: 768px) {
         margin-bottom: 40px;
        }

        .file-meta {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          height: 56px;

          @media (max-width: 768px) {
            flex-direction: column; /* 모바일에서 버튼을 세로로 배치 */
            justify-self: flex-start;
            align-items: flex-start;
            width: 100%;
            height: 84px;
          }

          img {
            width: 24px;
            height: 24px;
            display: block;
            @media (max-width: 768px) {
              display: none;
            }
          }

          .file-date {
            color: #b3b3b3;
          }

          .file-name {
            color: $darkToneDown;
          }
        }
      }
      .actions-pc {
        display: flex;
        flex-direction: row; /* 모든 화면에서 가로로 배치 */
        justify-content: flex-start;
        gap: 12px;

        .edit-button,
        .upload-button {
          background-color: transparent;
          padding: 10px 16px;
          border-radius: 8px;
          font-size: 14px;
          cursor: pointer;
          border: 1px solid #7a8495;
          flex-shrink: 0; /* 글자가 찌부되지 않게 함 */
          min-width: 58px; /* 버튼의 최소 너비 설정 */

          &:hover {
            background-color: #eff6ff;
          }
        }

        .upload-button {
          background-color: #7a8495;
          color: #fff;
          border: none;
          min-width: 120px; /* 버튼의 최소 너비 설정 */

          &:hover {
            background-color: #6b7280;
          }
        }
      }

      @media (min-width: 768px) {
        .actions-mo {
          display: none;
        }
      }

      @media (max-width: 768px) {
        .actions-pc {
          display: none;
        }

        .actions-mo {
          display: flex;

          .edit-button {
            width: 57px;
            height: 50px;
            padding: 8px 16px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid #C0C0C0;
            background: #FFF;
            white-space: nowrap; /* 텍스트를 한 줄로 만듦 */
            color: #626262;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 171.429% */

          }

          .upload-button {
            display: flex;
            height: 50px;
            padding: 16px 28px;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            flex: 1 0 0;
            border-radius: 8px;
            background: #7A8495;
            color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 157.143% */

          }
        }

        .flex-display {
          flex-direction: row; /* 모바일에서 버튼을 세로로 배치 */
          width: 100%;
          justify-content: center;
          gap: 10px;
          text-align: center;

        }
      }
    }
  }


  .action-buttons {
    display: flex;
    justify-content: space-around;
    gap: 24px;
    width: 100%;
    margin-top: 120px;

    @media (max-width: 768px) {
      flex-wrap: wrap; /* 카드들을 두 줄로 배치 가능하게 함 */
      gap: 0;
      margin-top: 30px;

      .br-tag {
        display: block;
      }
    }

    .br-tag {
      display: none;
    }
  }
}

.apply-button-container {
  text-align: center;

  .apply-button {
    width: 287px;
    background-color: $primary300;
    color: #fff;
    padding: 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;

    @media (max-width: 768px) {
      width: 100%;
    }

  }
}
