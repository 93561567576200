@import "src/assets/scss/foundations.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  height: 100vh;

  @media (max-width: 768px) {
    background-color: #FFFFFF;
    padding-top: 40px;
    gap: 0;
  }

  .card {
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    text-align: center;
    width: 100%;
    max-width: 400px; // 모바일과 데스크탑 크기 반응형 조절
    margin: 0 auto;
    margin-top: 75px;

    @media (min-width: 768px) {
      margin-top: 110px;
      border-radius: 12px;
      margin-bottom: 120px;
      max-width: 688px;
      padding: 40px;
    }

    @media (min-width: 768px) {
      max-width: 600px; // 데스크탑에서의 너비
    }
    .icon {
      display: flex; /* Flexbox를 사용 */
      justify-content: center; /* 수평으로 가운데 정렬 */
      align-items: center; /* 수직으로 가운데 정렬 */
      width: 100%; /* 부모 요소에 맞게 아이콘을 중앙에 맞춤 */
      height: auto; /* 높이는 자동으로 맞추기 */
      margin-bottom: 10px; /* 아이콘과 아래 텍스트 사이 간격 */
      img {
        max-width: 30px; /* 아이콘 크기 제한 */
        height: auto; /* 아이콘의 비율을 유지하며 크기 조정 */
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #111827;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 28px;
      }
    }

    .subtitle {
      font-size: 16px;
      color: #6b7280;
      margin-bottom: 50px;

      @media (min-width: 768px) {
        font-size: 18px; // 데스크탑에서의 서브타이틀 크기
      }

      .br-tag {
        display: block;

        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.form-card {
  background-color: #fff;
  width: 100%;
  padding: 24px;

  @media (min-width: 768px) {
    margin-top: 80px;
    border-radius: 12px;
    margin-bottom: 120px;
    max-width: 688px;
    padding: 40px;
  }
}

.title {
  color: #2D2D2D;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  margin-bottom: 8px;
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
}

/* 설명 텍스트 스타일 */
.subtitle {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.section-title {
  @include body('medium');
  color: #626262;
  margin-bottom: 20px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 13px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 12px;

  & label {
    color: var(--Dark-, #626262);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
}

.radio-input {
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #DCDFE3;
  position: relative;
  cursor: pointer;
  background-size: cover; /* 아이콘 크기 맞춤 */
  background-position: center; /* 중앙 정렬 */

  &:checked {
    background-color: #1d4ed8;
    background-image: url("https://an2-dev-fundbloc-public.s3.ap-northeast-2.amazonaws.com/edu/radio_check.png"); /* 선택된 아이콘 경로 */
  }
}

/* 에러 스타일 추가 */
.input-error {
  width: 100%;
  padding: 12px;
  margin-left: 12px;
  border-radius: 8px;
  border: 2px solid #ef4444; /* 빨간 테두리 */
  font-size: 14px;
  color: #333;
  margin-bottom: 4px;

  &:focus {
    outline: none;
    border-color: #dc2626;
  }

  @media (min-width: 768px) {
    padding: 14px;
    font-size: 16px;
  }
}

.error-message {
  font-size: 12px;
  color: #ef4444;
  margin-bottom: 16px;
  margin-left: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

.input-normal {
  width: 100%;
  padding: 12px;
  margin-left: 12px;
  border-radius: 8px;
  border: 1px solid #e5e7eb; /* 기본 테두리 */
  font-size: 14px;
  color: #333;

  &:focus {
    border-color: #1d4ed8;
    outline: none;
  }

  @media (min-width: 768px) {
    padding: 14px;
    font-size: 16px;
  }
}

/* 유의사항 스타일 */
.notice {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 16px;

  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 8px;
    color: #6b7280;
  }

  .required {
    color: #1d4ed8;
    font-weight: bold;
  }

  .notice-checked {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  .checkbox-input {
    appearance: none; /* 기본 체크박스 스타일 제거 */
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 1px solid #DCDFE3;
    background-size: cover; /* 아이콘 크기 맞춤 */
    background-position: center; /* 중앙 정렬 */
    background-repeat: no-repeat;

    &:checked {
      background-color: #1d4ed8; /* 선택된 상태의 배경색 */
      background-image: url("https://an2-dev-fundbloc-public.s3.ap-northeast-2.amazonaws.com/edu/radio_check.png"); /* 선택된 체크박스 아이콘 경로 */
    }
  }
}

.button {
  display: flex;
  justify-content: space-between; /* 좌우로 끝과 끝에 배치 */
  align-items: center; /* 세로 가운데 정렬 */
  width: 100%; /* 부모 요소의 너비만큼 차지 */
  padding-top: 50px;

  .next-button {
    @include body('small');
    color: $primary300;
    border: 1px solid $primary300;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:disabled {
      cursor: not-allowed;
    }

    @media (min-width: 800px) {
      &:hover {
        background-color: $primary300;
        color: #fff;
      }
    }
  }

  .prev-button {
    @include body('small');
    color: #676a6e;
    border: 1px solid #676a6e;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @media (min-width: 800px) {
      &:hover {
        background-color: #676a6e;
        color: #fff;
      }
    }
  }

  @media (min-width: 768px) {
    font-size: 16px;
    padding-top: 30px;
  }
}

.container-gap {
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 34px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.section {
  margin-top: 30px;
}

.divider {
  width: 100%; /* 100% 너비 */
  height: 2px; /* 2px 높이 */
  background-color: #f5f7f9; /* 파란색 (#1d4ed8) */
  margin-bottom: 34px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}
