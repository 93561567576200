@import "src/assets/scss/foundations.scss";
#body {
  min-height: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  height: auto;
  padding-top: 80px;
  gap: 30px;

  @media (max-width: 768px) {
    background-color: #FFFFFF;
    padding-top: 40px;
    gap: 0;
  }
}
.card {
  background-color: #fff;
  width: 100%; /* 기본적으로 너비를 100%로 설정 */
  max-width: 600px; /* 최대 너비는 600px로 제한 */
  padding: 24px;
  margin: 0 auto; /* 가운데 정렬 */
  border-radius: 12px;

  @media (max-width: 768px) {
    padding: 16px; /* 모바일에서 패딩을 줄여 더 컴팩트하게 */
    max-width: 100%; /* 모바일에서는 너비를 화면에 맞춤 */
  }
}

.title {
  font-family: "Pretendard", sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 24px;
  display: flex; /* 타이틀과 카카오 연결 계정을 같은 줄에 배치 */
  justify-content: space-between; /* 좌우 배치 */
  align-items: center; /* 세로 가운데 정렬 */
}

// 카카오톡 연결 계정 스타일
.kakao-connect {
  display: flex;
  align-items: center; /* 텍스트와 아이콘을 세로 가운데 정렬 */
  font-size: 14px;
  font-weight: bold;
  color: #888; /* 텍스트 색상 */
}

.kakao-connect img {
  margin-left: 8px; /* 텍스트와 아이콘 사이 간격 */
  width: 24px;
  height: 24px;
}

// 회원 정보 섹션 스타일
.member-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.member-info__label {
  font-family: "Pretendard", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 14px; /* 모바일에서 레이블 글자 크기 줄임 */
  }
}

.member-info__input,
.member-info__email {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  font-family: "Pretendard", sans-serif;
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #ff9800;
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 14px; /* 모바일에서 입력 필드 폰트 크기 줄임 */
    padding: 12px; /* 모바일에서 패딩도 줄임 */
  }
}

// 알림 설정 섹션 스타일
.alert-settings {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.alert-settings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.alert-settings__toggle {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.alert-settings__toggle:checked {
  background-color: $primary300;
}

.alert-settings__toggle::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.alert-settings__toggle:checked::before {
  left: 22px;
}

.alert-settings__content {
  font-family: "Pretendard", sans-serif;
  font-size: 14px;
  color: #666;
}

.alert-settings__footer {
  font-family: "Pretendard", sans-serif;
  font-size: 12px;
  color: #999;
  text-align: left;
}

// 추가된 버튼 컨테이너 스타일
.withdraw-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 24px;
  max-width: 600px; /* 최대 너비는 600px로 제한 */
  padding-bottom: 85px;

  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
.diver {
  display: none;
}

.withdraw-button {
  @include body("xssmall");
  width: 45px;
  height: 20px;
  color: #9a9a9a;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

// 반응형 미디어 쿼리
@media (max-width: 768px) {
  .card {
    width: 100%;
    padding: 16px;
    margin-top: 8px;

    &:first-child {
      margin: 0 0 0 0;
    }
  }

  .withdraw-container {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    padding-right: 20px;
  }

  .title {
    font-size: 20px;
  }

  .member-info__label,
  .alert-settings__content {
    font-size: 12px;
  }

  .member-info__input,
  .member-info__email {
    height: 56px;
    padding: 16px;
    font-size: 14px;
  }

  .diver {
    display: block;
    border: 8px solid #f8f8f8;
    width: 100%;
  }

}

@media (max-width: 480px) {
  .card {
    width: 100%;
    padding: 16px;
    margin-top: 0;
  }

  .diver {
    display: block;
    border: 8px solid #f8f8f8;
    width: 100%;
  }

  .title {
    font-size: 20px;
  }

  .member-info__input,
  .member-info__email {
    height: 56px;
    padding: 16px;
    font-size: 14px;
  }
}

.popupContainer {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  max-height: 500px;
  overflow: scroll;

  .popupTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .popupText {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 16px;
    color: $text-weak;
  }

  .popupList {
    margin-top: 16px;

    .listTitle {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .listItem {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 8px;
      margin-left: 20px;
      color: $text-weak;
    }
  }
}

.popupFooter {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    background-color: $primary300;
    width: 100%;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
}
