/* LoginScreen.module.scss */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.desktopImage {
  display: none;
}

.mobileImage {
  display: block;
  max-width: 100%;
}

@media (min-width: 768px) {
  .desktopImage {
    display: block;
    max-width: 100%;
  }

  .mobileImage {
    display: none;
  }

  .title {
    font-size: 32px;
    text-align: center;
  }
}
