@import "src/assets/scss/foundations.scss";

.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;

  overflow-x: auto; /* 버튼이 한 줄로 유지되며 스크롤 가능 */
  white-space: nowrap; /* 줄바꿈 방지 */
  -webkit-overflow-scrolling: touch; /* 모바일에서 부드러운 스크롤 */
  background-color: $primary300; /* 파란색 배경 */
  border-radius: 77px;
  // 기본 작은 화면 크기 (모바일)
  width: 335px;
  height: 53px;

  // 큰 화면 크기 (데스크탑)
  @media (min-width: 768px) {
    width: 418px;
    height: 69px;
    border-radius: 100px;
  }

  .tab-button {
    @include body("small");
    border-radius: 77px;
    margin-right: 3px;
    width: 335px;
    height: 53px;
    border: 8px solid $primary300;

    // 큰 화면 크기 (데스크탑)
    @media (min-width: 768px) {
      border: 10px solid $primary300;
      border-radius: 50px;
      margin-right: 4px;
      padding-right: 8px;
    }

    &.active {
      background-color: white;
      color: $primary300;
    }

    &:not(.active) {
      background-color: $primary300; /* 파란색 배경 */
      color: white;
    }

    &:last-child {
      margin-right: 0; /* 마지막 버튼은 마진 제거 */
    }
  }

  @media (min-width: 768px) {
    .tab-button {
      @include body("large");
      width: 418px;
      height: 69px;
    }
  }
}
