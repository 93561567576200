.container {
  width: 100%;

  // 기본 PC 배경 이미지
  background-size: cover;

  .main {
    display: block;
  }
  .main-mo {
    display: none;
  }

  // 모바일 전용 배경 이미지
  @media (max-width: 768px) {
    width: 100%;
    .main {
      display: none;
    }
    .main-mo {
      display: block;
    }
  }
}
